import React from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { providers } from '@utils/providers';
import { Provider as ProviderScheme } from '@lib/graphql/generated';
import { Link } from '../Link/Link';

export interface ProviderProps extends PropsWithClassProps<'onlyText' | 'boxStyle'> {
  providerSlug?: string;
  provider?: ProviderScheme;
  text?: string;
  link?: string;
  style?: 'text' | 'box' | 'button';
  featured?: boolean;
}

export const Provider = ({
  provider,
  providerSlug,
  style,
  featured = false,
  link,
  text = 'ansehen',
  classProps,
}: ProviderProps) => {
  const providerData = provider || providers.find((e) => e.slug === providerSlug);

  const providerStyle = twMerge(
    'w-full rounded-full px-4 py-3 grid grid-cols-[auto,1fr] justify-between gap-4 border-0.5 border-gray-650/25 bg-white shadow-card-sm dark:bg-gray-820 hover:border-gray-650/75 dark:hover:border-white group transition-colors duration-150 min-w-[17rem] items-center cursor-pointer',
    featured && 'p-4 rounded-xl border-gray-650/25 dark:border-gray-650/65 h-full',
    classProps?.root,
  );

  const logo = providers.find((e) => e.slug === providerData?.slug)?.logo;

  return (
    <>
      {style === 'button' ? (
        <>
          {link ? (
            <div className={featured ? 'row-span-2' : ''}>
              <a href={link} target="_blank" rel="noreferrer">
                <div className={providerStyle}>
                  <div className={twMerge('grid h-4 w-10 items-center', featured && 'h-10 w-16')}>
                    {logo && (
                      <Image
                        src={logo}
                        alt={providerData?.name || providerSlug}
                        width={100}
                        height={60}
                        classProps={{ root: 'absolute w-full h-full object-left' }}
                        copyright=""
                      />
                    )}
                  </div>
                  <div className="justify-self-end">
                    {featured && (
                      <div className="text-right text-sm font-bold uppercase leading-header tracking-2px">
                        Auf {providerData?.name} ansehen
                      </div>
                    )}
                    <div
                      className={twMerge(
                        'text-3xs font-bold uppercase leading-body tracking-2px text-gray-500 transition-colors duration-150 group-hover:text-gray-820 dark:group-hover:text-white',
                        featured && 'text-right text-sm font-normal normal-case tracking-normal',
                      )}
                    >
                      {text}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ) : (
            <div className={providerStyle}>
              {logo && (
                <div className="grid items-center">
                  <Image src={logo} alt={providerData?.name || providerSlug} width={100} height={40} copyright="" />
                </div>
              )}
              <div className={twMerge('justify-self-end text-3xs font-bold uppercase tracking-2px text-gray-500')}>
                {text}
              </div>
            </div>
          )}
        </>
      ) : style === 'box' ? (
        <Link
          href={'/provider/' + provider.slug}
          className={twMerge(
            'grid aspect-square min-h-36 min-w-36 cursor-pointer items-center justify-center rounded-lg border-0.5 border-primary/0 bg-gradient-to-b from-gray-650/25 via-gray-500/25 to-gray-650/25 p-2 text-center text-sm font-bold uppercase tracking-2px text-white transition-colors duration-150 hover:border-primary',
            classProps?.boxStyle,
          )}
        >
          {logo ? (
            <Image src={logo} alt={providerData?.name} width="96" height="72" copyright="" />
          ) : (
            <span className="hyphenate">{providerData?.name}</span>
          )}
        </Link>
      ) : (
        <div className={twMerge('text-sm font-bold uppercase tracking-2px', classProps?.onlyText)}>
          {providerData?.name}
        </div>
      )}
    </>
  );
};
